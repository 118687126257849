import React from "react";
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import List from '@material-ui/icons/List';
import AutoDelete from '@material-ui/icons/GridOn';

import DashboardPage from "views/Dashboard/Dashboard.js";
import UserProfile from "views/UserProfile/UserProfile.js";
import Faqlist from "views/Faq/FaqList.js";
import Faqadd from "views/Faq/Faqadd.js";
import Faqedit from "views/Faq/Faqedit.js";
import privacylist from "views/privacy_policy/Privacy_List";
import privacyadd from "views/privacy_policy/Privacy_add";
import privacyedit from "views/privacy_policy/Privacy_edit";
import Disclaimerlist from "views/Disclaimer/Disclaimer_List";
import Disclaimeradd from "views/Disclaimer/Disclaimer_add";
import Disclaimeredit from "views/Disclaimer/Disclaimer_edit";
import TermsOfservicelist from "views/TermsOfService/TermsOfService_List";
import TermsOfserviceadd from "views/TermsOfService/termsOfService_add";
import TermsOfserviceedit from "views/TermsOfService/TermsOfService_edit";
import Settings from "views/UserProfile/UserSettings.js";
import Cmslist from "views/Cms/CmsList.js";
import CmsEdit from "views/Cms/Cmsedit.js";
import AddCategory from "views/Category/AddCategory.js";
import CategoryList from 'views/Category/CategoryList';
import AddCategorydetails from "views/Category/AddCategorydetails.js";
import EditCategory from "views/Category/EditCategory.js";
import TokenList from "views/Token/TokenList.js";
import ViewToken from "views/Token/ViewToken";
import NewsLetter from 'views/EmailTemplate/newsLetter';
import ViewReport from 'views/report/reportedit';
import reportlist from 'views/report/reportlist';
import UserList from './views/Users/UsersList';
import ViewUser from './views/Users/Useredit';
import CMSLIST from "views/CmsNew/CmsNewList";
import CMSEDIT from "views/CmsNew/CmsNewedit";
import TOKENPRICE from "views/TokenPrice/tokenprice";
// import CMSLIST from "views/CmsNew/CmsNewList";
import EditServiceFee from 'views/ServiceFee/editServiceFee'
import PROMOTIONLIST from 'views/promotion/promotion'
import Subscriberlist from "views/Subscriber/Subscriberlist.js";
import Subscriberadd from "views/Subscriber/Subscriberadd.js";
// import DashB from "../src/assets/img/sidebar-3.jpg"

import kyckyb from "views/kyb-kyc"
import SubscribeAmmt from "views/subcribeamt"
import BURNLIST from 'views/burned/burnlist'
import AddNFT from "views/nfttag/addnft";
import NFTLIST from 'views/nfttag/nftlist'

import PURCHASELIST from 'views/purchase/purchaseList';
// Layout Types

const dashboard_ic = require('../src/assets/img/dashboard_icon/dashboard.png')
const tokens_ic = require('../src/assets/img/dashboard_icon/tokens.png')

const user_ic = require('../src/assets/img/dashboard_icon/user.png')
const category_ic = require('../src/assets/img/dashboard_icon/category.png')
const faq_ic = require('../src/assets/img/dashboard_icon/faq.png')
const ppolicy_ic = require('../src/assets/img/dashboard_icon/privacy.png')
const toslist_ic = require('../src/assets/img/dashboard_icon/terms.png')
const disclaimer_ic = require('../src/assets/img/dashboard_icon/disclaimer.png')
const cmsmanage_ic = require('../src/assets/img/dashboard_icon/cms.png')
const promomanage_ic = require('../src/assets/img/dashboard_icon/promotions.png')
const reportmanage_ic = require('../src/assets/img/dashboard_icon/report.png')
const nftshare_ic = require('../src/assets/img/dashboard_icon/nft.svg')
const social_ic = require('../src/assets/img/dashboard_icon/social.png')
const servicefee_ic = require('../src/assets/img/dashboard_icon/fee.png')
const userprofile_ic = require('../src/assets/img/dashboard_icon/user_profile.png')
const kyc_ic = require('../src/assets/img/dashboard_icon/kyc-2.png')




const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    // icon: dashboard_ic,
    component: DashboardPage,
    layout: '/admin',
    menu: "/sidemenu",
    image: dashboard_ic
  },
  {
    path: "/TokenList",
    name: "Tokens",
    icon: List,
    component: TokenList,
    layout: "/admin",
    menu: "/sidemenu",
    image: tokens_ic
  },
  {
    path: "/viewToken/:Owner/:Id",
    name: "Tokens",
    icon: List,
    component: ViewToken,
    layout: "/admin",
    menu: ""
  },
  {
    path: "/UserList",
    name: "User",
    icon: List,
    component: UserList,
    layout: "/admin",
    menu: "/sidemenu",
    image: user_ic
  },
  {
    path: "/viewUser/:Id",
    name: "ViewUser",
    icon: List,
    component: ViewUser,
    layout: "/admin",
    menu: ""
  },
  // {
  //   path: "/subscriberlist",
  //   name: "Subscriber List",
  //   icon: List,
  //   component: Subscriberlist,
  //   layout: "/admin",
  //   menu : "/sidemenu"
  // },
  // {
  //   path : "/subscriberadd",
  //   name: "Subscriber Add",
  //   icon: List,
  //   component: Subscriberadd,
  //   layout: "/admin",
  //   menu : "/sidemenu"
  // },
  // {
  //   path: "/categorylist",
  //   name: "Category",
  //   icon: List,
  //   component: CategoryList,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: category_ic
  // },

  {
    path: "/EditCategory/:userId",
    name: "EditCategory",
    icon: List,
    component: EditCategory,
    layout: "/admin",
    menu: ""
  },
   {
    path: "/faqadd",
    name: "Faq add",
    icon: List,
    component: Faqadd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/disclaimer/:from",
    name: "disclaimer add",
    icon: List,
    component: Disclaimeradd,
    layout: "/admin",
    menu : ""
  },
  {

    path: "/termsOfservice/:from",
    name: "termsOfservice add",
    icon: List,
    component: TermsOfserviceadd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/PrivacyPolicy/:from",
    name: "PrivacyPolicy add",
    icon: List,
    component: privacyadd,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/faqList",
    name: "Faq List",
    icon: List,
    component: Faqlist,
    layout: "/admin",
    menu : "/sidemenu",
    image: faq_ic
  }, 
  {
    path: "/privacyPolicyList",
    name: "PrivacyPolicy List",
    icon: List,
    component: privacylist,
    layout: "/admin",
    menu : "/sidemenu",
    image: ppolicy_ic
  }, 
  {
    path: "/termsOfserviceList",
    name: "Terms Of service List",
    icon: List,
    component: TermsOfservicelist,
    layout: "/admin",
    menu : "/sidemenu",
    image: toslist_ic
  }, 
  {
    path: "/disclaimerList",
    name: "Disclaimer List",
    icon: List,
    component: Disclaimerlist,
    layout: "/admin",
    menu : "/sidemenu",
    image: disclaimer_ic
  }, 
  //  {
  //   path: "/cmslist",
  //   name: "CMS Management",
  //   icon: List,
  //   component: CMSLIST,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: cmsmanage_ic
  // },
  // {
  //   path: "/promotionlist",
  //   name: "Promotion Management",
  //   icon: List,
  //   component: PROMOTIONLIST,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: promomanage_ic
  // },
  // {
  //   path: "/purchasedlist",
  //   name: "Sales Report Management",
  //   icon: List,
  //   component: PURCHASELIST,
  //   layout: "/admin",
  //   menu: "/sidemenu"
  // },
  // {
  //   path: "/TokenPrice",
  //   name: "Token Price",
  //   icon: List,
  //   component: TOKENPRICE,
  //   layout: "/admin",
  //   menu: "/sidemenu"
  // },
  // {
  //   path: "/burnedlist",
  //   name: "Burn List Management",
  //   icon: List,
  //   component: BURNLIST,
  //   layout: "/admin",
  //   menu: "/sidemenu"
  // },
  {
    path: "/faqedit/:faqId",
    name: "Faq edit",
    icon: List,
    component: Faqedit,
    layout: "/admin",
    menu : "",
    image: dashboard_ic
  },
  {
    path: "/PrivacyPolicyedit/:faqId",
    name: "PrivacyPolicy edit",
    icon: List,
    component: privacyedit,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/disclaimeredit/:faqId",
    name: "disclaimer edit",
    icon: List,
    component: Disclaimeredit,
    layout: "/admin",
    menu : ""
  },
  {
    path: "/termsOfserviceedit/:faqId",
    name: "termsOfservice edit",
    icon: List,
    component: TermsOfserviceedit,
    layout: "/admin",
    menu : "",
    image: dashboard_ic
  },
  {
    path: "/AddCategory",
    name: "AddCategory",
    icon: List,
    component: AddCategory,
    layout: "/admin",
    menu: "",
    image: dashboard_ic
  },
  {
    path: "/AddCategorydetails",
    name: "AddCategorydetails",
    icon: List,
    component: AddCategorydetails,
    layout: "/admin",
    menu: "",
    image: dashboard_ic
  },

  // {
  //   path: "/cmslist",
  //   name: "CMS Management",
  //   icon: List,
  //   component: Cmslist,
  //   layout: "/admin",
  //   menu: "/sidemenu"
  // },
  // {
  //   path: "/reportlist",
  //   name: "Report Management",
  //   icon: List,
  //   component: reportlist,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: reportmanage_ic
  // },
  {
    path: "/ViewReport/:Id",
    name: "View Report",
    icon: List,
    component: ViewReport,
    layout: "/admin",
    menu: ""
  },
  {
    path: "/cmsEdit/:Id",
    name: "Cms Edit",
    icon: List,
    component: CMSEDIT,
    layout: "/admin",
    menu: ""
  },
  // {
  //   path: "/nfttag",
  //   name: "NFT Share Tag Management",
  //   icon: List,
  //   component: NFTLIST,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: nftshare_ic
  // },
  {
    path: "/addnfttag",
    name: "Add NFT TAG",
    icon: List,
    component: AddNFT,
    layout: "/admin",
    menu: "/"
  },
  {
    path: "/editnft/:id",
    name: "Edit NFT TAG",
    icon: List,
    component: AddNFT,
    layout: "/admin",
    menu: "/"
  },
  
  {
    path: "/settings",
    name: "Social Links",
    icon: List,
    component: Settings,
    layout: "/admin",
    menu: "/sidemenu",
    image: social_ic
  },
  {
    path: "/kybkyc",
    name: "KYC/KYB",
    icon: List,
    component: kyckyb,
    layout: "/admin",
    menu: "/sidemenu",
    image: kyc_ic
  },
  // {
  //   path: "/SubscribeAmt",
  //   name: "Subscribe Amount",
  //   icon: List,
  //   component: SubscribeAmmt,
  //   layout: "/admin",
  //   menu: "/sidemenu",
  //   image: social_ic
  // },
  {
    path: "/editserviceFee",
    name: "Service Fee Management",
    icon: List,
    component: EditServiceFee,
    layout: "/admin",
    menu: "/sidemenu",
    image: servicefee_ic
  },


  {
    path: "/user",
    name: "User Profile",
    icon: Person,
    component: UserProfile,
    layout: "/admin",
    menu: "/sidemenu",
    image: userprofile_ic
  },

];

export default dashboardRoutes;
