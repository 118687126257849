
let key = {};
var EnvName = 'demo';
let IPFS_IMG = "https://ipfs.infura.io/ipfs"
var networkVersion=''
var BNBProvider="";
let Back_Url=""
let decimalValues = 1000000000000000000;
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}
if (EnvName === "testlive") {
     
}
else if (EnvName === "production") {

}
else if(EnvName === "demo") {
    var exchangeAddress = "";
    var adminaddress = "0x025c1667471685c323808647299e5DbF9d6AdcC9";
    var API_URL = 'https://api.unblokdata.com';
    var IMAGE_URL = 'https://unblokdata.com/user';
    var PORT = 3000;
    Back_Url='https://api.unblokdata.com'
    var Front_URL=''
    // BNBProvider             =   "https://data-seed-prebsc-1-s1.binance.org:8545/";
    var trade = ("0xdD92b37EfEd185868Eeea7885a502C098EE4719d").toLowerCase();
    var multipleContract   =   "0xdD92b37EfEd185868Eeea7885a502C098EE4719d".toLowerCase();
    // networkVersion      =   '97';
    BNBProvider             =   "https://data-seed-prebsc-1-s3.binance.org:8545/";
    networkVersion      =   '97';
}
else if(EnvName === "testing") {
    var exchangeAddress = "";
    var adminaddress = "0x025c1667471685c323808647299e5DbF9d6AdcC9";
    var API_URL = 'https://nftdemo4.maticz.com/api';
    var IMAGE_URL = 'https://nftdemo4.maticz.com/images/user';
    var PORT = 9366;
    Back_Url='https://nftdemo4.maticz.com:9366'
    var Front_URL='https://nftdemo4.maticz.com/KOLBNU'
 
//////////////////avax//////////////

   // BNBProvider             =   'https://api.avax-test.network/ext/bc/C/rpc';
    // var trade = ("0x04805F34FDDBf8e898DFcff7Cf70C19653767d3E").toLowerCase();
    // var multipleContract   =   "0x005488612C3e02f7BdC9AaA2A52c75eC0C5042c6".toLowerCase();
    // networkVersion      =   '43113';

    //////////binance/////////////////
      BNBProvider             =   'https://data-seed-prebsc-1-s1.binance.org:8545/';
    var trade = ("0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2").toLowerCase();
    var multipleContract   =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase();
    networkVersion      =   '97';

}
else if(EnvName === "local") {
    ///////////////////////avax/////////////////
    // var exchangeAddress = "";
    // var adminaddress = "";
    // var API_URL = 'http://localhost:2002';
    // var IMAGE_URL = 'http://localhost:2002/images/user';
    // var PORT = 2002;
    // Back_Url='http://localhost:2002'
    // var Front_URL="http://localhost:3001/UNBLOK"
    // BNBProvider             =   "https://api.avax-test.network/ext/bc/C/rpc";
    // var trade = ("0x04805F34FDDBf8e898DFcff7Cf70C19653767d3E").toLowerCase();
    // var multipleContract   =   "0x005488612C3e02f7BdC9AaA2A52c75eC0C5042c6".toLowerCase();
    // networkVersion      =   '43113';
///////////////////////binance/////////////////////

var exchangeAddress = "";
var adminaddress = "0x025c1667471685c323808647299e5DbF9d6AdcC9";
var API_URL = 'http://localhost:2002';
var IMAGE_URL = 'http://localhost:2002/images/user';
var PORT = 2002;
Back_Url='http://localhost:2002'
var Front_URL="http://localhost:3001/UNBLOK"
var trade = ("0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2").toLowerCase();
var multipleContract   =   "0x66F6d4d8D2280c788337018F4C2947FEB4eDFFC2".toLowerCase();
// networkVersion      =   '97';
// BNBProvider             =   "https://data-seed-prebsc-1-s1.binance.org:8545/";
    BNBProvider             =   "https://api.avax-test.network/ext/bc/C/rpc";
    networkVersion      =   '43113';
}

key = {

    secretOrKey: "",
    Recaptchakey: "",
    // API:`${API_URL}:${PORT}/v1`,
    API:`${API_URL}/v1`,
    IMAGE_URL:IMAGE_URL,
    exchangeAddress:exchangeAddress,
    toasterOption:toasterOption,
    IPFS_IMG:IPFS_IMG,
    networkVersion:networkVersion,
    adminaddress:adminaddress,
    decimalValues:decimalValues,
    Back_Url:Back_Url,
    multipleContract:multipleContract,
    networkVersion:networkVersion,
    BNBProvider:BNBProvider,
    Front_URL:Front_URL,
    trade:trade,
    adminaddress:adminaddress
    
};

export default key;