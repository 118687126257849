import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//import avatar from "assets/img/faces/marc.jpg";
import isEmpty from "../lib/isEmpty";

import { updateSettings, getsettdata } from "../actions/users";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
  textDanger: {
    color: "red",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  kyc: "",
  kyb: "",
  subamt: "",
  // "Twitter" : "",
  // "Telegram" : "",
  // "Reddit" : "",
  // "Discord" : "",
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [userdet, setUser] = useState();
  const [formValue, setFormValue] = useState({});
  const [validateError, setValidateError] = useState({});

  // function
  const onChange = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let formData = { ...formValue, ...{ [id]: value } };
    setFormValue(formData);
  };

  const {
    kyb,
    kyc,
    subamt,
    // Twitter,
    // Telegram,
    // Reddit,
    // Discord,
  } = formValue;

  const handleFormSubmit = async (e) => {
    ////console.lo("saran");
    e.preventDefault();

    let reqData = {
      subamt,
      kyb,
      kyc,
      from: "kybkyc",
    };
    

    //  //console.lo(reqData);
    let { error, result } = await updateSettings(reqData);
    console.log("gfndgfnhdfg", error, result);
    if (isEmpty(error)) {
      toast.success("Updated Sucessfully", toasterOption);
      setValidateError("");
      history.push("/kybkyc");
    } else {
      setValidateError(error);
    }
  };

  const getUserData = async () => {
    var test = await getsettdata({ from: "kybkyc" });
    console.log("dfbgsdfbsfgbfg", test);
    let formdata = {};
    //console.lo(test.userValue);
    // formdata['fees'] = test.userValue.fees;
    // formdata['feesPlan'] = test.userValue.feesPlan;

    if (test && test.userValue) {
      formdata["kyb"] = test.userValue.Kyb;
      formdata["kyc"] = test.userValue.Kyc;
      formdata["subamt"] = test.userValue.subamt;
      // formdata['Twitter'] = test.userValue.Twitter;
      // formdata['Telegram'] = test.userValue.Telegram;
      // formdata['Reddit'] = test.userValue.Reddit;
      // formdata['Discord'] = test.userValue.Discord;
      //console.lo("----formdata", formdata)
      setFormValue(formdata);
    }
  };

  useEffect(() => {
    //logout(history)
    getUserData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <form
              className={classes.form}
              noValidate
              onSubmit={handleFormSubmit}
            >
              <CardHeader className="bar_bg" color="primary">
                <h4 className={classes.cardTitleWhite}>KYB/KYC Amount</h4>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="KYB Amount"
                      onChange={onChange}
                      value={kyb || ""}
                      id="kyb"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number", 
                      }}
                    />

                    {validateError.kyb && (
                      <span className={classes.textDanger}>
                        {validateError.kyb}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <CustomInput
                      labelText="KYC Amount"
                      onChange={onChange}
                      id="kyc"
                      value={kyc || ""}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number", 
                      }}
                    />
                    {validateError.kyc && (
                      <span className={classes.textDanger}>
                        {validateError.kyc}
                      </span>
                    )}
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Subscribe Amount($)"
                      onChange={onChange}
                      id="subamt"
                      value={subamt || ""}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "number", 
                      }}
                    />
                    {validateError.subamt && (
                      <span className={classes.textDanger}>
                        {validateError.subamt}
                      </span>
                    )}
                  </GridItem>
                  {/* <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Reddit Link"
                      onChange={onChange}
                      value={Reddit || ''}
                      id="Reddit"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {
                    validateError.Reddit && <span className={classes.textDanger}>{validateError.Reddit}</span>
                    }
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="FaceBook Link"
                      onChange={onChange}
                      value={Discord || ''}
                      id="Discord"
                      formControlProps={{
                        fullWidth: true
                      }}
                    />

                    {
                    validateError.Discord && <span className={classes.textDanger}>{validateError.Discord}</span>
                    }
                    </GridItem> */}
                </GridContainer>
              </CardBody>
              <CardFooter>
                <Button className="cmn_btn_bg" color="primary" type="submit">
                  Update
                </Button>
              </CardFooter>
            </form>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
